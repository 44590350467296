<template>
  <div>
    <template v-if="warehouseRows.length > 0 && activeModule === 'warehouse'">
      <!-- <div class="text-h5 text-center py-3 purple--text font-weight-medium">{{ $t('labels.warehouse_1') }}</div> -->
      <v-row
        v-for="(cols, rIndex) in warehouseRows"
        :key="`w_r_${rIndex}`"
        justify="center"
      >
        <v-col
          cols="12"
          sm="4"
          md="3"
          lg="3"
          xl="2"
          v-for="(item, index) in cols"
          :key="`item_${index}`"
        >
          <v-card class="dashboard-card card-custom-shadow" v-if="item.title">
            <router-link
              v-if="item.route"
              :to="{ name: item.route }"
              class="w-100 h-100 d-flex align-center justify-center text-center dashboard-item-link"
            >
              <div>
                <img :src="item.img" alt="" class="mb-2" />
                <h3>{{ item.title }}</h3>
              </div>
            </router-link>
            <a
              v-else
              :href="item.link"
              class="w-100 h-100 d-flex align-center justify-center text-center dashboard-item-link"
            >
              <div>
                <img :src="item.img" alt="" class="mb-2" />
                <h3>{{ item.title }}</h3>
              </div>
            </a>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <template
      v-if="
        isCustomerActivePos() && posRows.length > 0 && activeModule === 'pos'
      "
    >
      <!-- <div class="text-h5 text-center pt-5 pb-3 purple--text font-weight-medium">{{ $t('labels.pos') }}</div> -->
      <v-row
        v-for="(cols, rIndex) in posRows"
        :key="`p_r_${rIndex}`"
        justify="center"
      >
        <v-col
          cols="12"
          sm="4"
          md="3"
          lg="3"
          xl="2"
          v-for="(item, index) in cols"
          :key="`item_${index}`"
        >
          <v-card
            class="dashboard-card card-custom-shadow"
            v-if="item.title"
            :disabled="item.disabled"
          >
            <router-link
              v-if="item.route"
              :to="{ name: item.route }"
              class="w-100 h-100 d-flex align-center justify-center text-center dashboard-item-link"
            >
              <div>
                <img :src="item.img" alt="" class="mb-2" />
                <h3>{{ item.title }}</h3>
              </div>
            </router-link>
            <a
              v-else
              :href="item.link"
              class="w-100 h-100 d-flex align-center justify-center text-center dashboard-item-link"
            >
              <div>
                <img :src="item.img" alt="" class="mb-2" />
                <h3>{{ item.title }}</h3>
              </div>
            </a>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <template
      v-if="administrationRows.length > 0 && activeModule === 'administration'"
    >
      <!-- <div class="text-h5 text-center pt-5 pb-3 purple--text font-weight-medium">{{ $t('labels.administration') }}</div> -->
      <v-row
        v-for="(cols, rIndex) in administrationRows"
        :key="`p_f_${rIndex}`"
        justify="center"
      >
        <v-col
          cols="12"
          sm="4"
          md="3"
          lg="3"
          xl="2"
          v-for="(item, index) in cols"
          :key="`item_${index}`"
        >
          <v-card class="dashboard-card card-custom-shadow" v-if="item.title">
            <router-link
              v-if="item.route"
              :to="{ name: item.route }"
              class="w-100 h-100 d-flex align-center justify-center text-center dashboard-item-link"
            >
              <div>
                <img :src="item.img" alt="" class="mb-2" />
                <h3>{{ item.title }}</h3>
              </div>
            </router-link>
            <a
              v-else
              :href="item.link"
              class="w-100 h-100 d-flex align-center justify-center text-center dashboard-item-link"
            >
              <div>
                <img :src="item.img" alt="" class="mb-2" />
                <h3>{{ item.title }}</h3>
              </div>
            </a>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <template
      v-if="
        goodsProductionRows.length > 0 && activeModule === 'goods_production'
      "
    >
      <!-- <div class="text-h5 text-center pt-5 pb-3 purple--text font-weight-medium">{{ $t('labels.administration') }}</div> -->
      <v-row
        v-for="(cols, rIndex) in goodsProductionRows"
        :key="`p_f_${rIndex}`"
        justify="center"
      >
        <v-col
          cols="12"
          sm="4"
          md="3"
          lg="3"
          xl="2"
          v-for="(item, index) in cols"
          :key="`item_${index}`"
        >
          <v-card class="dashboard-card card-custom-shadow" v-if="item.title">
            <router-link
              v-if="item.route"
              :to="{ name: item.route }"
              class="w-100 h-100 d-flex align-center justify-center text-center dashboard-item-link"
            >
              <div>
                <img :src="item.img" alt="" class="mb-2" />
                <h3>{{ item.title }}</h3>
              </div>
            </router-link>
            <a
              v-else
              :href="item.link"
              class="w-100 h-100 d-flex align-center justify-center text-center dashboard-item-link"
            >
              <div>
                <img :src="item.img" alt="" class="mb-2" />
                <h3>{{ item.title }}</h3>
              </div>
            </a>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  name: "DashboardItems",
  data: () => ({
    items: [],
    posItems: [],
    administrationItems: [],
    goodsProductionItems: [],
    activeModule: null,
    warehouseRows: [],
    posRows: [],
    administrationRows: [],
    goodsProductionRows: [],
    isActiveWarehouse: false,
    isActivePos: false,
    isActiveAdministration: false,
    isActiveGoodsProduction: false,
  }),
  computed: {},
  created() {
    this.items = [
      {
        title: this.$t("labels.receipt_create"),
        img: require("@/assets/sidebars/receipt_create.png"),
        route: "GoodsReceiptCreate",
        active: false,
        permissions: ["customer_account", "goods_receipt_create"],
        modules: ["warehouse_receipt"],
      },
      {
        title: this.$t("labels.return_history"),
        img: require("@/assets/sidebars/return_history.png"),
        route: "GoodsReturnHistory",
        active: false,
        permissions: ["customer_account", "goods_return_history"],
        modules: ["warehouse_return_history"],
      },
      {
        title: this.$t("labels.create_order_single"),
        img: require("@/assets/sidebars/create_order_single.png"),
        route: "OrderCreateSingle",
        active: false,
        permissions: ["customer_account", "order_create_single"],
        modules: ["warehouse_order_create_single"],
      },
      {
        title: this.$t("labels.create_order_excel"),
        img: require("@/assets/sidebars/create_order_excel.png"),
        route: "OrderCreateExcel",
        active: false,
        permissions: ["customer_account", "order_create_excel"],
        modules: ["warehouse_order_create_excel"],
      },
      {
        title: this.$t("labels.create_order_e_market"),
        img: require("@/assets/sidebars/create_order_e_market.png"),
        route: "OrderCreateEMarket",
        active: false,
        permissions: ["customer_account", "order_create_e_market"],
        modules: ["warehouse_order_create_e_market"],
      },
      {
        title: this.$t("labels.goods_list"),
        img: require("@/assets/sidebars/goods_list.png"),
        route: "GoodsList",
        active: false,
        permissions: ["customer_account", "goods_manager"],
        modules: ["warehouse_goods_list"],
      },
      {
        title: this.$t("labels.order_list"),
        img: require("@/assets/sidebars/order_list.png"),
        route: "OrderList",
        active: false,
        permissions: ["customer_account", "order_manager"],
        modules: ["warehouse_order_list"],
      },
      {
        title: this.$t("labels.fast_report"),
        img: require("@/assets/sidebars/thongkenhanh.png"),
        route: "OrderReport",
        permissions: ["customer_account", "dashboard_manager"],
        modules: [],
      },
      {
        title: this.$t("labels.goods_transfer"),
        img: require("@/assets/sidebars/pos_chuyen_noi_bo.png"),
        route: "GoodsTransfer",
        permissions: ["customer_account", "goods_transfer"],
        modules: ["warehouse_goods_transfer"],
      },
      {
        title: this.$t("labels.employee_warning"),
        img: require("@/assets/sidebars/pos_nhap_kiem.png"),
        route: "EmployeeWarning",
        active: false,
        modules: ["warehouse_employee_warning"],
        permissions: ["customer_account"],
      },
    ];

    this.posItems = [
      {
        title: this.$t("labels.pos_goods_receipt"),
        img: require("@/assets/sidebars/pos_nhap_kiem.png"),
        route: "PosGoodsReceiptCreate",
        permissions: ["customer_account", "pos_goods_receipt_create"],
        modules: ["pos_receipt_create"],
      },
      {
        title: this.$t("labels.goods_transfer"),
        img: require("@/assets/sidebars/pos_chuyen_noi_bo.png"),
        route: "PosGoodsTransfer",
        permissions: ["customer_account", "pos_goods_transfer"],
        modules: ["pos_goods_transfer"],
      },
      {
        title: this.$t("labels.create_order_online"),
        img: require("@/assets/sidebars/create_order_single.png"),
        route: "PosOrderCreateSingle",
        active: false,
        permissions: ["customer_account", "pos_order_create_single"],
        modules: ["pos_order_create_single"],
      },
      {
        title: this.$t("labels.goods_list"),
        route: "PosGoodsList",
        img: require("@/assets/sidebars/goods_list.png"),
        permissions: ["customer_account", "pos_goods_manager"],
        modules: ["pos_goods_list"],
      },
      {
        title: this.$t("labels.pos_order_report"),
        route: "PosOrderList",
        img: require("@/assets/sidebars/pos_quan_ly_don.png"),
        permissions: ["customer_account", "pos_order_list"],
        modules: ["pos_order_list"],
      },
      {
        title: this.$t("labels.order_online_list"),
        route: "PosOrderOnlineList",
        img: require("@/assets/sidebars/order_list.png"),
        permissions: ["customer_account", "pos_order_online_list"],
        modules: ["pos_order_online"],
      },
    ];

    this.administrationItems = [
      {
        title: this.$t("labels.import_management"),
        img: require("@/assets/sidebars/receipt_history.png"),
        route: "AdministrationReceiptHistory",
        active: false,
        permissions: ["customer_account", "administration_receipt_history"],
        modules: [],
      },
      {
        title: this.$t("labels.business_results"),
        img: require("@/assets/sidebars/tai_chinh.png"),
        route: "AdministrationReportList",
        active: false,
        permissions: [
          "customer_account",
          "administration_report_revenue",
          "administration_report_up",
        ],
        modules: [],
      },
      {
        title: this.$t("labels.delivery_speed"),
        img: require("@/assets/sidebars/vanchuyen.png"),
        route: "AdministrationGoodsReport",
        active: false,
        permissions: ["customer_account", "administration_goods_report"],
        modules: [],
      },
      {
        title: this.$t("labels.sale_price"),
        img: require("@/assets/sidebars/goods_price.png"),
        route: "AdministrationGoodsPrice",
        active: false,
        permissions: ["customer_account", "administration_goods_price"],
        modules: [],
      },
      {
        title: this.$t("labels.cost_price"),
        img: require("@/assets/sidebars/goods_cost.png"),
        route: "AdministrationGoodsCost",
        active: false,
        permissions: ["customer_account", "administration_goods_cost"],
        modules: [],
      },
      {
        title: this.$t("labels.supplier"),
        img: require("@/assets/sidebars/supplier.png"),
        route: "AdministrationSupplier",
        active: false,
        permissions: ["customer_account", "administration_supplier"],
        modules: [],
      },
      {
        title: this.$t("labels.supplies"),
        img: require("@/assets/sidebars/supplies.png"),
        route: "AdministrationSupply",
        active: false,
        permissions: ["customer_account", "administration_supply"],
        modules: [],
      },
      {
        title: this.$t("labels.category"),
        img: require("@/assets/sidebars/danhmuc.png"),
        route: "AdministrationGoodsCategory",
        active: false,
        permissions: ["customer_account", "administration_goods_category"],
        modules: [],
      },
      {
        title: this.$t("labels.profile"),
        img: require("@/assets/sidebars/hoso.png"),
        route: "AdministrationGoodsProfile",
        active: false,
        permissions: ["customer_account", "administration_goods_profile"],
        modules: [],
      },
      {
        title: this.$t("labels.pos_goods_display_request"),
        img: require("@/assets/sidebars/pos_trung_bay.png"),
        route: "AdministrationPosGoodsDisplayRequest",
        permissions: [
          "customer_account",
          "administration_pos_goods_display_request",
        ],
        modules: ["administration_pos_goods_display_request"],
      },
      {
        title: this.$t("labels.goods_missing"),
        img: require("@/assets/sidebars/confused.png"),
        route: "AdministrationGoodsMissing",
        active: false,
        permissions: ["customer_account", "administration_goods_missing"],
        modules: [],
      },
      {
        title: this.$t("labels.pos_promotion"),
        route: "AdministrationPosPromotionConfig",
        img: require("@/assets/sidebars/pos_khuyen_mai.png"),
        permissions: [
          "customer_account",
          "administration_pos_promotion_config",
        ],
        modules: ["administration_pos_promotion"],
      },
      {
        title: this.$t("labels.pos_accrue_point"),
        route: "AdministrationPosAccruePointConfig",
        img: require("@/assets/sidebars/pos_tich_diem.png"),
        permissions: [
          "customer_account",
          "administration_pos_accrue_point_config",
        ],
        modules: ["administration_pos_accrue_point"],
      },
      {
        title: this.$t("labels.pos_voucher"),
        route: "AdministrationPosVoucherConfig",
        img: require("@/assets/sidebars/pos_ma_giam_gia.png"),
        permissions: ["customer_account", "administration_pos_voucher_config"],
        modules: ["administration_pos_voucher"],
      },
      {
        title: this.$t("labels.zns_history"),
        img: require("@/assets/sidebars/zns.png"),
        route: "AdministrationZns",
        active: false,
        permissions: ["customer_account", "administration_zns"],
        modules: ["administration_zns"],
      },
      {
        title: this.$t("labels.goods_debt"),
        img: require("@/assets/sidebars/debt.png"),
        route: "AdministrationGoodsDebt",
        active: false,
        permissions: ["customer_account", "administration_goods_debt"],
        modules: ["administration_goods_debt"],
      },
      {
        title: this.$t("labels.present"),
        route: "AdministrationPresent",
        img: require("@/assets/sidebars/gift.png"),
        active: false,
        permissions: ["customer_account", "administration_present"],
        modules: ["administration_present"],
      },
      {
        title: this.$t("labels.physical_combo_program"),
        route: "AdministrationPhysicalCombo",
        img: require("@/assets/sidebars/physical_combo.png"),
        active: false,
        permissions: ["customer_account", "admin_physical_combo"],
        modules: ["admin_physical_combo"],
      },
      {
        title: this.$t("labels.delivery_shop"),
        route: "DeliveryShopConfig",
        img: require("@/assets/sidebars/tracking.png"),
        active: false,
        permissions: ["customer_account", "administration_delivery_shop"],
        modules: ["administration_delivery_shop"],
      },
    ];

    this.goodsProductionItems = [
      {
        title: this.$t("labels.product_profile"),
        img: require("@/assets/sidebars/sx_hososanpham.png"),
        route: "GoodsProductionRecord",
        active: false,
        permissions: ["customer_account", "goods_production_record"],
        modules: ["goods_production"],
      },
      {
        title: this.$t("labels.warehouse_receipt_history"),
        img: require("@/assets/sidebars/receipt_history.png"),
        route: "MaterialReceiptHistory",
        active: false,
        permissions: ["customer_account", "material_receipt_history"],
        modules: ["goods_production"],
      },
      {
        title: this.$t("labels.production_request"),
        img: require("@/assets/sidebars/sx_ycsx.png"),
        route: "GoodsProductionRequirement",
        active: false,
        permissions: ["customer_account", "goods_production_requirement"],
        modules: ["goods_production"],
      },
      {
        title: this.$t("labels.quantitative_standard"),
        img: require("@/assets/sidebars/sx_dinhmuc.png"),
        route: "GoodsProductionQuantitative",
        active: false,
        permissions: ["customer_account", "goods_production_quantitative"],
        modules: ["goods_production"],
      },
    ];
  },
  mounted() {
    this.setRows();
    this.$root.$on("setActiveModule", this.setActiveModule);
  },
  beforeDestroy() {
    this.$root.$off("setActiveModule", this.setActiveModule);
  },
  methods: {
    checkMenuItem(item) {
      return (
        (!item.permissions ||
          item.permissions.length === 0 ||
          this.checkPermission(item.permissions)) &&
        (!item.modules ||
          item.modules.length === 0 ||
          this.checkModule(item.modules)) &&
        this.checkMenuMobile(item.mobileCheck) &&
        this.checkMenuDesktop(item.desktopCheck)
      );
    },
    getWarehouseRows() {
      const rows = [];
      let isActive = false;
      let key = 0;
      this.items.forEach((item) => {
        if (this.checkMenuItem(item)) {
          if (!rows[key]) {
            rows[key] = [];
          }
          rows[key].push(item);
          if (rows[key].length === 3) {
            key++;
          }
          isActive = true;
        }
        /* if (!rows[key]) {
                  rows[key] = []
                }
                item.disabled = item.disabled || !this.checkMenuItem(item)
                rows[key].push(item)
                if (rows[key].length === 3) {
                  key++
                } */
      });

      const lastItemLength = (rows[key] && rows[key].length) || 3;
      if (lastItemLength < 3) {
        for (let i = lastItemLength; i < 3; i++) {
          rows[key].push({});
        }
      }
      return {
        warehouseRows: rows,
        isActiveWarehouse: isActive,
      };
    },
    getPosRows() {
      const rows = [];
      let isActive = false;
      let key = 0;
      this.posItems.forEach((item) => {
        if (this.checkMenuItem(item)) {
          if (!rows[key]) {
            rows[key] = [];
          }
          rows[key].push(item);
          if (rows[key].length === 3) {
            key++;
          }
          isActive = true;
        }
        /* if (!rows[key]) {
                  rows[key] = []
                }
                item.disabled = item.disabled || !this.checkMenuItem(item)
                rows[key].push(item)
                if (rows[key].length === 4) {
                  key++
                } */
      });

      const lastItemLength = (rows[key] && rows[key].length) || 4;
      if (lastItemLength < 3) {
        for (let i = lastItemLength; i < 3; i++) {
          rows[key].push({});
        }
      }
      return {
        posRows: rows,
        isActivePos: isActive,
      };
    },
    getAdministrationRows() {
      const rows = [];
      let isActive = false;
      let key = 0;
      const sortedList = [...this.administrationItems].sort((a, b) =>
        a.title.localeCompare(b.title)
      );
      sortedList.forEach((item) => {
        if (this.checkMenuItem(item)) {
          if (!rows[key]) {
            rows[key] = [];
          }
          rows[key].push(item);
          if (rows[key].length === 3) {
            key++;
          }
          isActive = true;
        }
        /* if (!rows[key]) {
                  rows[key] = []
                }
                item.disabled = item.disabled || !this.checkMenuItem(item)
                rows[key].push(item)
                if (rows[key].length === 1) {
                  key++
                } */
      });

      const lastItemLength = (rows[key] && rows[key].length) || 4;
      if (lastItemLength < 3) {
        for (let i = lastItemLength; i < 3; i++) {
          rows[key].push({});
        }
      }
      return {
        administrationRows: rows,
        isActiveAdministration: isActive,
      };
    },
    getgoodsProductionRows() {
      const rows = [];
      let isActive = false;
      let key = 0;
      this.goodsProductionItems.forEach((item) => {
        if (this.checkMenuItem(item)) {
          if (!rows[key]) {
            rows[key] = [];
          }
          rows[key].push(item);
          if (rows[key].length === 3) {
            key++;
          }
          isActive = true;
        }
      });

      const lastItemLength = (rows[key] && rows[key].length) || 4;
      if (lastItemLength < 3) {
        for (let i = lastItemLength; i < 3; i++) {
          rows[key].push({});
        }
      }
      return {
        goodsProductionRows: rows,
        isActiveGoodsProduction: isActive,
      };
    },
    setRows() {
      const { warehouseRows, isActiveWarehouse } = this.getWarehouseRows();
      const { posRows, isActivePos } = this.getPosRows();
      const { administrationRows, isActiveAdministration } =
        this.getAdministrationRows();
      const { goodsProductionRows, isActiveGoodsProduction } =
        this.getgoodsProductionRows();

      this.warehouseRows = [...warehouseRows];
      this.posRows = [...posRows];
      this.administrationRows = [...administrationRows];
      this.goodsProductionRows = [...goodsProductionRows];
      this.isActiveWarehouse = isActiveWarehouse;
      this.isActivePos = isActivePos;
      this.isActiveAdministration = isActiveAdministration;
      this.isActiveGoodsProduction = isActiveGoodsProduction;

      const modules = [];

      if (this.isActiveWarehouse) {
        modules.push("warehouse");
      }
      if (this.isActivePos) {
        modules.push("pos");
      }
      if (this.isActiveAdministration) {
        modules.push("administration");
      }
      if (this.isActiveGoodsProduction) {
        modules.push("goods_production");
      }
      const activeModule = (modules && modules[0]) || null;
      this.setActiveModule(activeModule);
    },
    setActiveModule(mod) {
      this.activeModule = mod;
    },
  },
};
</script>
